<template>
  <div class="login_contain">
    <div class="login_box">
      <div style="text-align:center">
        <img src="../../assets/img/bgtit.png" style="width:100%;margin:0 auto;"/>
      </div>
      <div class="login_cont">
        <div class="login_form">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <el-form-item prop="name">
              <el-input type="text" class="name" placeholder="请输入账号" auto-complete="off" v-model="loginForm.sphone" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" class="password" placeholder="请输入密码" auto-complete="off" v-model="loginForm.password" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <div class="login_code" @click="noRePwd">忘记密码?</div>
            <el-form-item>
              <el-button type="primary" class="loginBtn" @click.native.prevent="submitForm">立即登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from '@/api/login'
export default {
  data () {
    return {
      loginForm: {
        sphone: '',
        password: ''
      },
      loginRules: {
        sphone: [
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur'}
        ]
      }
    }
  },
  created () {
    // window.localStorage.setItem('token', 'Bonfire token')
  },
  methods: {
    noRePwd () {
      this.$message.warning('请联系管理员')
    },
    submitForm () {
      this.$refs.loginForm.validate(valid => {
        if (!valid) return
        const SM4 = require('gm-crypt').sm4
        let sm4Config = {
          // 配置sm4参数
          key: 'EB0AA4FE11EC99B6',// 这里这个key值是跟后端要的
          mode: 'ecb', // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
          cipherType: 'base64'
        }
        let sm4 = new SM4(sm4Config); // 这里new一个函数，将上面的sm4Config作为参数传递进去。然后就可以开心的加密了
        // let Account = sm4.encrypt(this.loginForm.sphone); //账号加密
        let Pwd = sm4.encrypt(this.loginForm.password); // 密码加密
        const params = {
          sphone: this.loginForm.sphone,
          password: Pwd
        }
        userLogin(params).then(res => {
          if (res.status === 1) {
            window.localStorage.setItem('token', 'Bearer ' + res.data.access_token)
            window.localStorage.setItem('user', JSON.stringify(res.data))
            window.sessionStorage.setItem('State', '1')
            this.$store.commit('setLoginState', !this.$store.getters.loginState)
            this.$router.push({ path: '/main' })
          } else {
            this.$message.error(res.message)
          }
        })
      })

    }
  }
}
</script>

<style scoped>
.login_contain {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(../../assets/img/bg.png) no-repeat;
  background-size: 100% 100%;
}
.login_box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -250px;
  margin-left: -300px;
  overflow: hidden;
  /* padding: 10px; */
}
.login_cont {
  width: 90%;
  position: relative;
  margin: 10px auto 0px;
  background: rgb(255, 255, 255, 0.4);
  border-radius: 8px;
  border: 10px solid #C1B8AF;
  backdrop-filter: blur(4px);
  padding: 10px;
  box-sizing: border-box;
  height: 416px;
}
.login_form {
  height: 416px;
}
.login_form .el-form {
  padding: 30px;
}
.name >>> .el-input__inner, .password >>> .el-input__inner, .loginBtn {
  backdrop-filter: blur(4px);
  width: 100%;
  height: 60px;
  background: rgb(0, 0, 0, 0.05);
  box-shadow: inset 0px 3px 6px 1px rgb(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  padding-left: 30px;
  color: #576271;
}
.password >>> .el-input__inner {
  margin-top: 15px;
}
.name ::placeholder, .password ::placeholder{
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 23px;
  color: #576271;
}
.login_code {
  font-size: 14px;
  font-family: 'PingFang SC-Medium, PingFang SC';
  font-weight: 500;
  color: #576271;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}
.loginBtn {
  margin-top: 35px;
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 500;
  line-height: 23px;
  color: #FFFFFF;
  cursor: pointer;
  background: #3C84EF;
}
</style>
